
import { defineComponent, onMounted, computed} from "vue";
import Quill from "quill/dist/quill.js";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {
    Dropdown2
  },
  setup() {

    const store = useStore();
      const user = computed(() => {
        return store.getters.currentUser;
      });

    onMounted(() => {

     

      const editorId = "kt_forms_widget_1_editor";

      // init editor
      const options = {
        modules: {
          toolbar: {
            container: "#kt_forms_widget_1_editor_toolbar",
          },
        },
        theme: "snow",
      };

      // Init editor
      new Quill("#" + editorId, options);
    });

    return {
      user
    }
  },
});
